.card-swiper {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  transition-property: transform;
  display: block;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  box-shadow: 0px 0px 8px 3px rgba(111, 111, 111, 0.4)
}

.card-my-swiper {
  width: 100%;
  height: 410px;
  margin: 20px auto;
}
.card-my-swiper .swiper-wrapper{
  height: 96%;
  margin-top: 10px;
}

.card-carousel {
  background-color: #fafbfc;
  padding: 50px 24px;
  position: relative;
}
.carousel__controls-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  width: calc(20% - 10px);
  padding: 0 12px;
  background-color: #fafbfc;
  z-index: 1;
}

.carousel__controls {
  display: flex;
}
.mt-5 {
  margin-top: 1.5rem !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 21%) !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 72%) !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: black;
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 50%;
  top: var(--swiper-navigation-top-offset, 40%) !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: black;
}

.swiper-button-next:hover.swiper-button-next,
.swiper-button-prev:hover.swiper-button-prev {
  color: white !important;
}

.carousel__slide-box__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 30px;
  bottom: 12%;
  background-color: var(--bodyGrey);
  height: 12.2%;
  border-radius: 0 0 14px 14px;
  padding: 0 7px;
}

.carousel__add,
.carousel__controls button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  background: transparent;
  border: 2px solid #032742;
  border-radius: 22px;
  cursor: pointer;
  transition: 0.2s;
}
.card-bottom-title{
  font-size: 17px;
  font-weight: bold;
}

@media screen and (min-width: 1216px) {
  .g4-homepage .carousel__slide-box__title {
    padding: 0 30px;
    width: calc(100% - 60px);
    height: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .carousel__add,
  .carousel__controls button {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .carousel__controls-overlay h3 {
    font-size: 33px;
    line-height: 2.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .g4-homepage .g4-best-sellers-carousel__slide-box__title {
    padding: 0 30px;
    width: calc(100% - 60px);
    height: 40px;
  }
}

@media (max-width: 768px) {
  .card-carousel{
    display: none;
  }
}