.hair-header-image {
  background-image: url("/src/components/operations/hair/images/hair-header-fade.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.body-header-image {
  background-image: url("/src/components/operations/body/images/body-header-fade.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.face-header-image {
  background-image: url("/src/assets/faceHeader.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
}

.skin-header-image {
  background-image: url("/src/components/operations/skin/images/skin-header-fade.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
}

.operations-header {
  background-color: #F5F5F5;
  height: 500px;
}

.operations-responsive-header{
  display: none;
}

.operations-header-text {
  padding-left: 120px;
  padding-top: 90px;
}

.operations-header-title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 20px;
}

.operations-header-title2 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 5px;
}

.operations-header-link {
  font-size: 18px !important;
  line-height: 31px;
}
.bodyoperations-header-link {
  font-size: 18px !important;
  line-height: 30px;
}

.operations-header-link-div{
  border: 2px solid #032742;
  width: 250px;
  height: 35px;
  padding-left: 3px;
  border-radius: 100px;
  margin-top: 10px;
  display: flex;
}
.bodyoperations-header-link-div{
  border: 2px solid #032742;
  width: 400px;
  height: 34px;
  padding-left: 3px;
  border-radius: 100px;
  margin-top: 10px;
  display: flex;
}

.link-icon-div{
  width: 36px;
}

.link-icon {
  font-size: 12px;
  position: relative;
  bottom: 1.5px;
  background-color: #032742;
  color: white;
  border-radius: 100%;
  padding: 7px;
}

.operations-header-link-div:hover{
  background-color: #032742;
  transition: all 0.25s;
  color: white;
  
}

.operations-header-link-div:active{
  background-color: #01080d;
  color: white;
}

.operations-header-link-div:active .link-icon{
  background-color: #01080d;
  color: white;
}

.bodyoperations-header-link-div:hover{
  background-color: #032742;
  color: white;
}

.bodyoperations-header-link-div:active{
  background-color: #01080d;
  color: white;
}

.bodyoperations-header-link-div:active .link-icon{
  background-color: #01080d;
  color: white;
}

.operations-content {
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 20px;
}

.operations-main-title {
  font-size: 33px;
  margin-bottom: 15px;
  margin-top: 40px;
  font-weight: bold;
}

.operations-title1 {
  font-size: 27px;
  margin-bottom: 15px;
  margin-top: 40px;
  font-weight: bold;
}

.operations-title2 {
  font-size: 27px;
  margin-bottom: 15px;
  font-weight: bold;
}

.operations-title3{
  font-size: 18px;
  
}

.operations-text {
  font-size: 16px;
  /* text-align: justify; */
}

.accordion-button {
  background-color: #ffffff !important;
}

.accordion-body {
  background-color: #ffffff !important;

}

.accordion-button {
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #142D45;
}

.operations-hr {
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  color: rgb(149, 149, 149);
}

.hair-img1 {
  background-image: url("/src/components/operations/hair/images/hair1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 460px;
  border-radius: 10px;
}

.hair-img2 {
  background-image: url("/src/components/operations/hair/images/hair2.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 460px;
  border-radius: 10px;
}

.hair-img3 {
  background-image: url("/src/components/operations/hair/images/hair3.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 460px;
  border-radius: 10px;
}

.hair-img4 {
  background-image: url("/src/components/operations/hair/images/hair4.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

.hair-img5 {
  background-image: url("/src/components/operations/hair/images/hair5.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 540px;
  border-radius: 10px;
}

.skin-img1 {
  background-image: url("/src/components/operations/skin/images/skin1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 380px;
  border-radius: 10px;
}

.skin-img2 {
  background-image: url("/src/components/operations/skin/images/skin2.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 420px;
  border-radius: 10px;
}

.skin-img3 {
  background-image: url("/src/components/operations/skin/images/skin3.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 300px;
  border-radius: 10px;
}

.skin-img4 {
  background-image: url("/src/components/operations/skin/images/skin4.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 350px;
  border-radius: 10px;
}

.skin-img5 {
  background-image: url("/src/components/operations/skin/images/skin5.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 390px;
  border-radius: 10px;
}

.skin-img6 {
  background-image: url("/src/components/operations/skin/images/skin6.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 410px;
  border-radius: 10px;
}



.terleme-img1 {
  background-image: url("/src/components/operations/body/images/terleme-img1.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}
.terleme-img2 {
  background-image: url("/src/components/operations/body/images/terleme-img2.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 280px;
  border-radius: 10px;
}
.migren-img1 {
  background-image: url("/src/components/operations/body/images/migren-img1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.migren-img2 {
  background-image: url("/src/components/operations/body/images/migren-img2.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.selülit-img1 {
  background-image: url("/src/components/operations/body/images/selülit-img1.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 410px;
  border-radius: 10px;
}

.selülit-img2 {
  background-image: url("/src/components/operations/body/images/selülit-img2.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 310px;
  border-radius: 10px;
}
.selülit-img3 {
  background-image: url("/src/components/operations/body/images/selülit-img3.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 380px;
  border-radius: 10px;
}

.gıdı-img1{
  background-image: url("/src/components/operations/body/images/gıdıeritme-img1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 360px;
  border-radius: 10px;
}
.gıdı-img2 {
  background-image: url("/src/components/operations/body/images/gıdıeritme-img2.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 330px;
  border-radius: 10px;
}

.operations-hr {
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  color: rgb(149, 149, 149);
}


@media (max-width: 768px) {
  .operations-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
  }

  .operations-header-text {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .operations-header-title {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .operations-header {
    height: 100%;
    padding-top: 21px;
    padding-bottom: 20px;
  }

  .operations-header-link-div{
    width: 100%;
    height: 42px;  
    padding-right: 15px;
    line-height: 15px;
    text-align: start;
    align-items: center;
    margin-bottom: 12px;
    font-size: 15px;
  }

  .bodyoperations-header-link-div{
    width: 100%;
    height: 42px;  
    padding-right: 15px;
    line-height: 15px;
    text-align: start;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .link-icon-div{
    width: 7%;
    display: flex;
    padding-top: 5px;
    margin-right: 12px;
  }

  .operations-header-image {
    display: none;
  }

  .operations-responsive-header {
    display: block;
    padding-top: 25px;
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%;
  }

  .operations-main-title{font-size: 27px;}

  .operations-title1{font-size: 24px;}

  .operations-title2{font-size: 24px;}

  .operations-image {
    height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
 


}