.containerw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  flex-wrap: wrap;
  padding: 2px;
}

.textContainere {
  max-width: 60%;
  flex: 1;
  min-width: 250px;
}

.titlee {
  font-family: gt-america-medium;
  font-size: 5em;
  text-align: left !important;
  margin-left: 150px;
}

.subtitlee {
  font-size: 1.2em;
  margin: 0 0 20px 0;
}

.buttonq {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.imageT {
  max-width: 25%;
  border-radius: 10px;
  flex: 1;
  min-width: 200px;
  margin-right: 100px;
}

@media (max-width: 768px) {
  .containerw {
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px;
    height: 430px;
    display: flex;
    justify-content: center;
    
  }

  .titlee {
    font-size: 1.9em;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 25px;
    margin-left: -10px;
    font-family: gt-america-medium;
  }

  .imageT {
    max-width: 65%;
    margin-top: 20px;
    margin-bottom: -8px;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;

  }
}

