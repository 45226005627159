:root {
  --border-color: #fff5;
  --w-image: 560px;
  --calculate: calc(3 / 2.2);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main-content {
  height: 72% !important;
}
.image {
  margin-bottom: 40px !important;
  margin-top: -90px;
  margin-left: 78px;
}
/* .image {
  margin-top: -50px;
} */
.carousel {
  width: 100%;
  height: 710px;
  overflow: hidden;
  position: relative;
}
.carousel .list {
  height: 100%;
  position: relative;
}
.carousel .list::before {
  position: absolute;
  width: var(--w-image);
  height: 100%;
  content: "";
  top: 0;
  left: calc(100% - calc(var(--w-image) * var(--calculate)));
  z-index: 10;
  pointer-events: none;
}
.carousel .list::after {
  position: absolute;
  top: 50px;
  left: 50px;
  content: "";
  width: 400px;
  height: 300px;
  z-index: 10;
  pointer-events: none;
  border-radius: 20px 50px 110px 230px;
  filter: blur(150px);
  opacity: 0.6;
}
.carousel .list .item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.carousel .list .item .image {
  width: var(--w-image);
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(100% - calc(var(--w-image) * var(--calculate)));
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  font-weight: 500;
}
.carousel .list .item .image img {
  width: 80%;
  margin-bottom: 150px;
}
.carousel .list .item .image .slider-image-2 {
  /* width: 100% !important; */
  width: 100%;
}
.carousel .list .item .image figcaption {
  display: none;
}

.carousel .list .item .main-content {
  height: 100%;
  display: grid;
  grid-template-columns: calc(100% - var(--w-image) * var(--calculate));
}
.carousel .list .item .main-content .content {
  padding: 93px 23px 79px 243px;
}
.carousel .list .item .main-content .content h2 {
  font-size: 3.4rem;
  line-height: 1.2;
  font-weight: 700;
}
.list .carousel-img {
  position: absolute;
  width: 27%;
  top: 56%;
  left: 3%;
  z-index: 998;
  animation: animate2 2s infinite ease alternate;
}
@keyframes animate2 {
  to {
    transform: translateY(15px);
  }
}
.arrows {
  position: absolute;
  width: calc(100% - calc(var(--w-image) * var(--calculate)));
  display: grid;
  grid-template-columns: repeat(2, 50px);
  grid-template-rows: 50px;
  justify-content: end;
  gap: 10px;
  z-index: 10;
  top: 61%;
  /* margin-left: 10px; */
}
.arrows i {
  color: black;
}
.arrows button {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: #fff;
  font-size: large;
  font-weight: bold;
  box-shadow: 0 10px 40px #5555;
  cursor: pointer;
  transition: 0.5s;
  height: 43px;
}
.arrows button:hover {
  background-color: #eee5;
}
.carousel .list .item {
  display: none;
}
.carousel .list .item.active,
.carousel .list .item.other_1,
.carousel .list .item.other_2 {
  display: block;
}
.carousel .list .item.active {
  z-index: 2;
}
.carousel .list .item.other_1,
.carousel .list .item.other_2 {
  pointer-events: none;
}
.carousel .list .item.active .main-content {
  animation: showContent 1s ease-in-out 1 forwards;
}
@keyframes showContent {
  from {
    clip-path: circle(0% at 70% 50%);
  }
  to {
    clip-path: circle(100% at 70% 50%);
  }
}
.next .item.other_1 {
  z-index: 1;
}
.next .item .image img,
.next .item .image figcaption {
  animation: effectNext 0.5s ease-in-out 1 forwards;
}
@keyframes effectNext {
  from {
    transform: translateX(calc(var(--transform-from)));
  }
  to {
    transform: translateX(calc(var(--transform-from) - var(--w-image)));
  }
}
.next .item.active .image {
  --transform-from: var(--w-image);
}
.next .item.other_1 .image {
  z-index: 3;
  --transform-from: 0px;
  overflow: hidden;
}
.next .item.other_2 .image {
  z-index: 3;
  --transform-from: calc(var(--w-image) * 2);
}

/* prev */
.prev .list .item .image img,
.prev .list .item .image figcaption {
  animation: effectPrev 0.5s ease-in-out 1 forwards;
}
@keyframes effectPrev {
  from {
    transform: translateX(calc(var(--transform-from)));
  }
  to {
    transform: translateX(calc(var(--transform-from) + var(--w-image)));
  }
}
.prev .list .item.active .image {
  --transform-from: calc(var(--w-image) * -1);
  overflow: hidden;
}
.prev .list .item.other_1 .image {
  --transform-from: 0px;
  z-index: 3;
}
.prev .list .item.other_2 .image {
  z-index: 3;
  --transform-from: var(--w-image);
}
.prev .list .item.other_2 .main-content {
  opacity: 0;
}

@media screen and (min-width: 1216px) {
  .carousel .list .item .image img {
    margin-bottom: 109px !important;
  }
}

@media screen and (max-width: 1023px) {
  :root {
    --calculate: 1;
    --w-image: 400px;
  }
  .carousel .list .item .main-content .content h2 {
    font-size: 3em;
  }
}

@media screen and (max-width: 376px) {
  .image {
    margin: 0;
  }
  .columns {
    margin-left: -0.75rem !important;
  }

  .carousel .list .item .image {
    width: 140% !important;
    /* margin-left: 12% !important; */
    justify-content: center;
    top: -23%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .carousel .list .item .image img {
    width: 73% !important;
    margin-bottom: -41.1% !important;
    margin-left: 45px !important;
  }
  .carousel .list .item .image .slider-image-2 {
    margin-left: 13% !important;
    margin-bottom: -42.1% !important;
    /* width: 75vw !important; */
  }
  .carousel .list .item .main-content .content h2 {
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .carousel .list .item .image figcaption {
    display: flex;
    flex-direction: column;
    max-width: 343px;
    font-weight: bold;
    font-size: 20px;
  }
  .carousel .list .item .main-content .content {
    display: none;
  }
  .main-content {
    height: 81%;
  }
  .arrows {
    left: 50%;
    justify-content: center;
    align-items: start;
    top: 74%;
  }
  .list .carousel-img {
    width: 32%;
    top: 80%;
  }
  /* .carousel .list .item:nth-child(3) .image img {
    width: 194%;
    margin-top: 39px;
    margin-right: -40px;
  }
  .carousel .list .item:nth-child(3) .image figcaption {
    margin-top: 50px;
  }
  .carousel .list .item:nth-child(2) .image img {
    width: 63%;
  }
  .carousel .list .item:nth-child(4) .image img {
    width: 87%;
    margin-top: 30px;
  }
  .carousel .list .item:nth-child(4) .image figcaption {
    margin-top: 70px;
    width: 110%;
  }
  .carousel .list .item:nth-child(1) .image img {
    width: 220% !important;
    margin-top: 20px;
    margin-right: -40px;
  }
  .carousel .list .item:nth-child(1) .image figcaption {
    margin-top: 20px;
    width: 160%;
    text-align: center;
    line-height: 1 !important;
    font-size: 11px;
  } */
}

@media screen and (max-width: 391px) {
  .carousel .list .item .image figcaption {
    max-width: 388px !important;
    /* padding: 0 10px !important; */
    align-items: center !important;
  }
  .carousel .list .item .image .slider-image-2 {
    width: 69%;
    margin-left: 8% !important;
    margin-bottom: -39.1%;
  }
  .carousel .list .item .image img {
    margin-left: 29px !important;
  }
  .arrows {
    top: 68% !important;
  }
}
@media screen and (max-width: 394px) {
  .carousel .list .item .image .slider-image-2 {
    margin-left: 7% !important;
  }
  .carousel .list .item .image img {
    margin-left: 25px !important;
  }
}

@media screen and (max-width: 435px) {
  .carousel .list .item .image {
    width: 140%;
    margin-left: 14%;
  }
  .carousel .list .item .image figcaption {
    max-width: 329px;
  }
  .image {
    margin: 0;
  }
  .carousel .list .item .image {
    width: 140%;
    margin-left: 2%;
    justify-content: center;
    top: -23%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .carousel .list .item .image img {
    width: 77vw;
    margin-bottom: -38.1%;
    margin-left: 4px;
  }
  .carousel .list .item .image .slider-image-2 {
    width: 69%;
    margin-left: 4%;
    margin-bottom: -39.1%;
  }
  .carousel .list .item .main-content .content h2 {
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .carousel .list .item .image figcaption {
    display: flex;
    flex-direction: column;
    max-width: 343px;
    font-weight: bold;
    font-size: 20px;
  }
  .carousel .list .item .main-content .content {
    display: none;
  }
  .main-content {
    height: 76.7% !important;
  }
  .arrows {
    left: 50%;
    justify-content: center;
    align-items: start;
    top: 70%;
  }
  .list .carousel-img {
    width: 32%;
    top: 80%;
  }
  .columns {
    margin-left: -2.85rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .g2-container-mobile {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
    /* padding-right: 28%; */
  }
  .g-blog__main__hero__header {
    font-family: gt-america-medium;
  }
  .is-uppercase {
    text-transform: uppercase !important;
  }
  .has-text-weight-bold {
    font-weight: 700 !important;
  }
  .g2-text-great-mobile {
    font-size: 32px !important;
    line-height: 2.2rem;
    text-align: center;
  }
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .columns {
    margin-left: -1.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
  }
  .g-blog__main__hero__description {
    line-height: 1.2;
  }
  .g2-text-small-mobile {
    font-size: 14px !important;
    text-align: center;
  }
  .carousel .list .item .image .slider-image-2 {
    width: 77vw !important;
  }
  .carousel .list .item .image figcaption {
    padding-bottom: 18px;
  }
}
