.header-title {
  font-size: 54px;
  font-weight: bold;
  margin-bottom: 30px;
}
.about-img1 {
  /* background-image: url("/src/pages/about/aboutImages/ekip.jpg"); */
  /* background-size: cover; */
  /* background-position: center; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 370px;
  border-radius: 10px;
}

.about-img2 {
  /* background-image: url("/src/pages/about/aboutImages/genetikphoto.png"); */
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  max-height: 450px;
  min-height: 250px;
  border-radius: 10px;
}
.image2, .image1{
  border-radius: 10px;
}

.header-text {
  font-size: 16px;
}

.about-title {
  font-size: 27px;
  margin-bottom: 15px;
  font-weight: bold;
}

.about-text {
  font-size: 16px;
}
.aboutBody {
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 56px;
}

.bottomLine{
  border-bottom: 2px solid white;
}

.bottomLine:hover {
  border-color: #8998eb;
  transition: all ease-in-out 0.2s;
}

@media (max-width: 1200px) {
  .aboutBody {
    padding-left: 200px;
    padding-right: 200px;
  }
  .aboutBody .aboutContent {
    display: flex;
    flex-direction: column;
  }
  .aboutBody .aboutContentScd {
    display: flex;
    flex-direction: column-reverse;
  }
  .aboutBody .aboutContent img,
  .aboutBody .aboutContentScd img {
    max-width: 500px !important;
  }
}
@media (max-width: 991px) {
  #aboutHeader {
    flex-direction: column-reverse;
  }
  .aboutBody {
    padding-left: 150px;
    padding-right: 150px;
  }
}
@media (max-width: 768px) {
  .header-title {
    /* margin-top: -10%; */
    transition: all ease-in-out 1s;
    font-size: 32px;
  }
  .phoneixImg {
    transition: all ease-in-out 1s;
    /* display: none; */
    height: 260px !important;
    width: 100% !important;
  }
  .about-logo{
    display: none;
  }
  .image2, .image1{
    object-fit: cover !important;
  }

  .aboutBody {
    padding-left: 15px;
    padding-right: 15px;
  }
  .aboutHeadImg {
    width: 200px !important;
  }
  #journey,
  .about-title {
    margin-top: 2%;
  }
  .aboutLinks {
    display: flex;
    padding-left: 15px;
    
  }
  .about-title {
    font-size: 27px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .spaceTitle {
    margin-top: 5% !important;
}
.about-img1 {
  height: 245px;
}
}