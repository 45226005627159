@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Lobster&display=swap");
.animated-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1px;
  justify-content: center;
  margin-top: 2px;
  margin-left: 25px;
}

.animatex {
  position: relative;
  height: 52px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-left: 20px;
}

.static-text {
  font-size: 42px;
  /* font-family: "Tinos", serif; */
  margin-right: 20px;
}

.animated-text {
  height: 128px;
  display: flex;
  flex-direction: column;
  text-align: left !important;
}

.animated-word {
  font-size: 42px;
  /* font-family: "Tinos", serif; */
  display: flex;
  align-items: center;
  height: 130px;
  flex-direction: row;
  text-align: left !important;
}

.sub-text {
  font-family: "Caveat", cursive;
  font-size: 30px !important;
  margin-left: -30px;
  margin-top: -15px;
}

/* Mobil cihazlar için stiller */
@media (max-width: 767px) {
  .animated-text-container {
    padding: 10px;
    margin-left: -4px;
  }
  .animatex {
    height: 75px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 20px; */
  }

  .static-text {
    font-size: 30px;
    margin-right: 10px;
    margin-top: -4px;
    margin-bottom: 5px;
    font-family: secondary;
  }

  .animated-text {
    height: 200px;
    margin-top: -31px;
  }

  .animated-word {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    /* font-weight: 500; */
    height: 260px;
    /* margin: 30px 0; */
    font-family: secondary;
  }
  .sub-text {
    font-size: 18px;
    margin-left: 0;
    margin-top: -5px;
    /* margin-bottom: -0px !important; */
    font-family: "Caveat", cursive;
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
  }

  .sub-text p {
    font-size: 21px;
    max-width: 318px;
    text-align: center;
    line-height: 1;
  }
}
