.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f5f5f5;
  margin-top: 30px;
  border-radius: 20px !important;
  margin-left: 30px;
  margin-right: 30px;
  height: 700px;
  color: #142d45 !important;
}

.text-containerx {
  text-align: center;
  margin-bottom: 2rem;
  color: #142d45 !important;

}

.main-text {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #142d45 !important;

}

.sub-text {
  font-size: 1rem;
  margin-bottom: -4px;
  color: #142d45 !important;

}

.description {
  font-size: 1rem;
  color: #142d45 !important;
  font-weight: 400;
}
.leading-8{
  line-height: 1.5rem;
}

.icon-container {
  display: flex;
  justify-content: center;
  color: #142d45 !important;

}

.icon-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  width: 20%;
  color: #142d45 !important;

}

.icone {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0.5rem;
  color: #142d45 !important;

}

.vegan {
  background-image: url("icon1.png");
} 

.cruelty-free {
  background-image: url("icon2.png");
}

.derm-tested {
  background-image: url("icon3.png");
}

.icon-label {
  font-size: 0.9rem;
  text-align: center;
  color: #142d45 !important;

}

.icon-container{
  background-color: white;
  width: 70%;
  height: 20%;
  border-radius: 20px;
  margin-top: 20px;
}



@media (max-width: 768px) {
  .section-container {
    padding: 1rem; 
    margin: 10px; 
    height: auto;
  }

  .text-containerx {
    margin-bottom: 1rem; 
  }

  .main-text {
    font-size: 3rem; 
    line-height: 1.2; 
    margin-bottom: 10px;
  }
  

  .sub-text {
    font-size: 1.5rem; 
    /* margin-bottom: 0.5rem; */
  }

  .icon-container {
    width: 95%; 
    height: 90px; 
    
  }
  .cruelty-free{
    margin-left: 5px;
  }

  .icon-item {
    width: 30%; 
    margin: 0 0.5rem; 
  }

  .icon {
    width: 40px; 
    height: 40px;
  }

  .icon-label {
    font-size: 0.8rem; 
  }
}