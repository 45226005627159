body.menu-open {
  overflow: hidden;
}

.sidebar {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 768px) {
    display: flex;
  }

  .bg {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: #f27d41;

    .sidebar-img-1,
    .sidebar-img-2,
    .sidebar-img-3,
    .sidebar-img-4 {
      position: absolute;
      width: 20%;
      animation: animate2 2s infinite ease alternate;

      &:nth-child(2) {
        top: 7%;
        right: 7%;
        animation: animate 2s infinite ease alternate;
      }

      &:nth-child(3) {
        bottom: 10%;
        left: 7%;
        animation: animate 2s infinite ease alternate;
      }

      &:nth-child(4) {
        bottom: 10%;
        right: 8%;
        animation: animate3 2s infinite ease alternate;
      }
    }

    @keyframes animate {
      to {
        transform: translateY(20px);
      }
    }

    @keyframes animate2 {
      to {
        transform: translateY(15px);
      }
    }

    @keyframes animate3 {
      to {
        transform: translateY(25px);
      }
    }

    .links {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      a {
        font-size: 20px;
        color: #fff;
        text-decoration: none;
      }

      .dropdown {
        position: relative;

        .dropbtn {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }

        .dropdown-content {
          display: none;
          flex-direction: column;
          position: absolute;
          top: 30px;
          background-color: #f27d41;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          z-index: 1;
          min-width: 228px; /* Standart genişlik */
          left: -5%; /* Ortalamak için */
          transform: translateX(-50%); /* Ortalamak için */

          a {
            color: #fff;
            padding: 5px;
            text-decoration: none;
            font-size: 18px;
            text-align: center;

            &:hover {
              background-color: rgb(226, 128, 80);
            }
          }
        }

        &:hover .dropdown-content {
          display: flex;
        }
      }
    }
  }

  .toggle-btn {
    z-index: 999;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 27px;
    left: 38px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
