:root {
  --blue: rgb(58, 136, 176);
  --orange: rgb(226, 128, 80);
  --white: #ffff;
  --textColor: #032742;
  --lightGrey: #fafbfc;

  /* #dbdbdb */
  --darkGrey1: rgb(219, 219, 219);
  --bodyGrey: #f5f5f5;
  --darkGrey: rgb(20, 45, 69);
  --black: rgb(8, 19, 24);
  --lightGreen: rgb(93, 181, 180);
  --mediumGreen: rgb(73, 156, 140);
  --darkGreen: rgb(48, 115, 144);
  --boldText: "Arial Rounded MT Bold", sans-serif;
  --normalText: "Arial MT", sans-serif;
  --swiper-navigation-size: 25px !important;
}

body {
  font-size: 15px;
  color: var(--textColor);
  line-height: 1.7;
  letter-spacing: 0;
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: white !important;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins", sans-serif;
}
a {
  color: inherit;
  text-decoration: inherit;
}
