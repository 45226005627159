.modal-content {
    background-color: rgb(245, 245, 245);
  .modal-body {
    height: 176px; /* Sabit yükseklik */
    overflow: hidden; /* Taşan içeriği gizle */
    display: -webkit-box; /* Flexbox düzeni */
    -webkit-line-clamp: 7; /* Görüntülenecek satır sayısı */
    -webkit-box-orient: vertical; /* Dikey hizalama */
    line-height: 1.5; /* Satır yüksekliği */
    padding: 18px !important;
  }
  .modal-footer {
    justify-content: start;
    padding: 19px 0;
    border-top: 0;

    .btn {
      color: var(--textColor);
      font-size: 15px;
      padding-right: 0;
      padding-top: 2px;
      background-color: transparent;
    border: none;
    }
    i{
      font-size: 19px;
      margin-bottom: 7px;
    }
  }
}
