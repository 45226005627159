.iv-header-image {
  background-image: url("/src/components/services/images/iv-header-fade5.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.ozon-header-image {
  background-image: url("/src/components/services/images/ozon-header-fade5.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.kok-header-image {
  background-image: url("/src/components/services/images/kokhucre-header-fade5.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.eksozom-header-image {
  background-image: url("/src/components/services/images/eksozom-header-fade5.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.genclesme-header-image {
  background-image: url("/src/components/services/images/genclesme-header-fade5.png");
  background-size: cover;
  width: 100%;
  height: 500px;
}

.genetik-header-image {
  background-image: url("/src/components/services/images/genetik-header-fade5.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
}

.services-header {
  background-color: #F5F5F5;
  height: 500px;
}

.services-responsive-header{
  display: none;
}

.services-header-text {
  padding-left: 120px;
  padding-top: 90px;
}

.services-header-title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 20px;
}

.services-header-title2 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 5px;
}

.services-header-link {
  font-size: 18px !important;
  line-height: 31px;
}

.services-header-link-div{
  border: 2px solid #032742;
  width: 485px;
  height: 35px;
  padding-left: 3px;
  border-radius: 100px;
  margin-top: 10px;
  display: flex;
}

.kokhucre-link-div{
  width: 385px;
}

.link-icon-div{
  width: 36px;
}

.link-icon {
  font-size: 12px;
  position: relative;
  bottom: 1.5px;
  background-color: #032742;
  color: white;
  border-radius: 100%;
  padding: 7px;
}

.services-header-link-div:hover{
  background-color: #032742;
  transition: all 0.25s;
  color: white;
}

.services-header-link-div:active{
  background-color: #01080d;
  color: white;
}

.services-header-link-div:active .link-icon{
  background-color: #01080d;
  color: white;
}

.services-content {
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 20px;
}

.contact-card {
  height: 320px;
  background-color: #B3D5E1;
  border-radius: 10px;
}

.contact-card2 {
  height: 320px;
  background-color: #91d5ce;
  border-radius: 10px;
}

.contact-card-image {
  background-image: url("/src/components/services/images/services-card-image.png");
  background-size: cover;
  background-position: center;
  height: 100%;
}

.contact-card-image2 {
  background-image: url("/src/components/services/images/services-card-image2.png");
  background-size: cover;
  background-position: center;
  height: 100%;
}

.contact-card-title {
  font-family: "Caveat", cursive;
  font-size: 38px;
  margin-bottom: 20px;
}

.contact-card-link {
  padding: 15px;
  width: 30%;
  display: block;
  margin: auto;
  background-color: #0c6180;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: inherit;
}

.contact-card-link:hover {
  background-color: #062a37;
}

.services-main-title {
  font-size: 33px;
  margin-bottom: 15px;
  margin-top: 40px;
  font-weight: bold;
}

.services-title1 {
  font-size: 27px;
  margin-bottom: 15px;
  margin-top: 40px;
  font-weight: bold;
}

.services-title2 {
  font-size: 27px;
  margin-bottom: 15px;
  font-weight: bold;
}

.services-title3{
  font-size: 18px;
  
}

.services-text {
  font-size: 16px;
  /* text-align: justify; */
}


.iv-img1 {
  background-image: url("/src/components/services/images/iv3.jpg");
  background-size: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 470px;
  border-radius: 10px;
}

.accordion-button {
  background-color: #ffffff !important;
}

.accordion-body {
  background-color: #ffffff !important;

}

.accordion-button {
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #142D45;
}

.services-hr {
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  color: rgb(149, 149, 149);
}

.ozone-img1 {
  background-image: url("/src/components/services/images/ozone1.png");
  background-size: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 570px;
  border-radius: 10px;
}

.kok-img1 {
  background-image: url("/src/components/services/images/kok1.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 420px;
  border-radius: 10px;
}

.kok-img2 {
  background-image: url("/src/components/services/images/kok2.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 375px;
  border-radius: 10px;
}

.kok-img3 {
  background-image: url("/src/components/services/images/kok3.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 380px;
  border-radius: 10px;
}

.kok-img4 {
  background-image: url("/src/components/services/images/kok4.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 535px;
  border-radius: 10px;
}

.genclesme-img1 {
  background-image: url("/src/components/services/images/genclesme1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.genclesme-img2 {
  background-image: url("/src/components/services/images/genclesme2.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.genclesme-img3 {
  background-image: url("/src/components/services/images/genclesme3.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 275px;
  border-radius: 10px;
}

.genclesme-img4 {
  background-image: url("/src/components/services/images/genclesme4.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 530px;
  border-radius: 10px;
}

.genclesme-img5 {
  background-image: url("/src/components/services/images/genclesme5.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 475px;
  border-radius: 10px;
}

.genclesme-img6 {
  background-image: url("/src/components/services/images/genclesme6.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 555px;
  border-radius: 10px;
}

.kriyoterapi-img1 {
  background-image: url("/src/components/operations/body/images/kriyoterapi-img1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 240px;
  border-radius: 10px;
}
.kriyoterapi-img2 {
  background-image: url("/src/components/operations/body/images/kriyoterapi-img2.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}
.hiperbarik-img1 {
  background-image: url("/src/components/operations/body/images/hiperbarik-img1.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 370px;
  border-radius: 10px;
}
.hiperbarik-img2 {
  background-image: url("/src/components/operations/body/images/hiperbarik-img2.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 470px;
  border-radius: 10px;
}

.skin-img7 {
  background-image: url("/src/components/operations/skin/images/skin7.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 320px;
  border-radius: 10px;
}

.skin-img8 {
  background-image: url("/src/components/operations/skin/images/skin8.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 580px;
  border-radius: 10px;
}

.eksozom-img {
  background-image: url("/src/components/services/images/eksozom1.jpg");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 330px;
  border-radius: 10px;
}

.genetik-img1 {
  background-image: url("/src/components/services/images/genetik1.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 275px;
  border-radius: 10px;
}

.genetik-img2 {
  background-image: url("/src/components/services/images/genetik2.png");
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 350px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .services-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
  }

  .services-header-text {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .services-header-title {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .services-header {
    height: 100%;
    padding-top: 21px;
    padding-bottom: 20px;
  }

  .services-header-link-div{
    width: 100%;
    height: 42px;  
    padding-right: 15px;
    line-height: 15px;
    text-align: start;
    align-items: center;
    margin-bottom: 12px;
    font-size: 15px;
  }

  .link-icon-div{
    width: 7%;
    display: flex;
    padding-top: 5px;
    margin-right: 12px;
  }

  .services-header-image {
    display: none;
  }

  .services-responsive-header {
    display: block;
    padding-top: 25px;
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%;
  }

  .services-main-title{font-size: 27px;}

  .services-title1{font-size: 24px;}

  .services-title2{font-size: 24px;}

  .services-image {
    height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .contact-card {
    height: 250px;
  }

  .contact-card2 {
    height: 250px;
  }

  .contact-card-image {
    height: 250px;
    border-radius: 10px;
  }

  .contact-card-title {
    font-size: 25px;
    margin-top: 63px;
    margin-bottom: 8px;
  }

  .contact-card-link {
    height: 40px;
    margin-top: 8px;
    font-size: 12px;
    padding-top: 10px;
    width: 60%;
  }

  


}