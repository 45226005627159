#dubai .blog-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  height: 450px; 
  transition: box-shadow 0.3s ease; 
}

#dubai .blog-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
}

#dubai .blog-image {
  width: 100%;
  height: 200px;
}

#dubai .blog-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#dubai .blog-category {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
}

#dubai .technology {
  color: #0073e6;
}

#dubai .popular {
  color: #ff6347;
}

#dubai .design {
  color: #8a2be2;
}

#dubai .blog-title {
  font-size: 18px;
  margin: 10px 0;
  color: #032742;
}

#dubai .blog-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
  flex-grow: 1; 
}

#dubai .blog-author {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

#dubai .author-left {
  display: flex;
  align-items: center;
}

#dubai .author-right {
  margin-left: auto; 
}

#dubai .icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

#dubai .read-time,
#dubai .watch-time {
  font-size: 12px;
  color: #555;
}

#dubai .blog-time {
  font-size: 12px;
  color: #888;
}

@media (max-width: 768px) {
  #dubai .blog-card {
    max-width: 45%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  #dubai .blog-card {
    max-width: 100%;
  }
}
