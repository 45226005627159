:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

body {
  background: var(--bg);
}

.swiper-container {
  max-width: 124rem;
  padding: 3rem 1rem 3rem 1rem;
  margin: 0 auto;
  display: none;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.mobil-swiper-slide {
  width: 63% !important;
  height: 42rem;
  position: relative;
}

.swiper-3d {
  perspective: 244px !important;
}

.mobil-swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9rem;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}
@media (max-width: 768px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: auto !important;
  }
  .carousel__add_mobil {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 34px;
    height: 34px;
    padding: 0;
    background: transparent;
    border: 2px solid var(--lightGrey);
    border-radius: 22px;
    cursor: pointer;
    transition: 0.2s;
    position: absolute;
    top: 49%;
    right: 19%;
  }
  .mobile-swiper-h3 {
    position: absolute;
    top: 50.3%;
    left: 19.2%;
    color: #ffffff;
    font-size: 20px;
    text-shadow: 6px 6px 6px rgba(0, 0, 0, 1);
  }
  .swiper-slide img {
    width: 18rem !important;
    height: 22rem !important;
    margin: 0 auto;
    /* filter: brightness(0.5); */
    z-index: -1;
  }
  .swiper-container {
    display: block;
  }
  .swiper-pagination {
    display: none;
  }
}

@media (max-width: 500px) {
  .swiper_container {
    /* height: auto; */
    height: 400px;
  }
  .mobil-swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }

  .swiper-wrapper {
    height: 43% !important;
  }
}
