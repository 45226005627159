.scroll-container {
  height: 250vh;
  background-image: url("prlx.png");
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  transition: background-position 0.5s ease;
  color: var(--textColor) !important;
  margin-top: -120px;
  border-radius: 40px;
  margin-left: 3px;
  margin-right: 3px;
  line-height: 1.3;
}

/* Content container */
.text-container {
  position: sticky;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  margin-top: -62%;
  max-width: 900px;
  line-height: 1.2;
  font-family: "Tinos", serif;
}
.bigT {
  font-size: 65px !important;
  font-weight: 200;
  font-family: "Caveat", cursive;
  line-height: 1.1;
  text-align: center;
  margin-left: -12px;
}
.nrml {
  font-weight: 80 !important;
  font-size: 50px !important;
  text-align: center;
  /* font-family: "Caveat", cursive; */
}

/* Title */
.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

/* Arrow icon */
.arrow {
  font-size: 3.7rem;
  animation: bounce 2s infinite;
}

/* Spacer */
.spacer {
  height: 200vh; /* Adjust as needed */
}

/* Bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.logoo {
  max-width: 70%;
  display: block;
  margin: 0 auto;
  transition: none; /* Ensure no unintended transitions affect the logo */
  width: auto; /* Explicitly set width */
  height: auto; 
}

/* Cream bottles */
.cream-bottles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none; /* Ensure cream bottles do not interfere with scrolling */
  z-index: 1;
}

.cream-bottle {
  position: absolute;
  width: 200px; /* Increased size of cream bottles */
  height: auto;
  transition: transform 0.5s ease;
}

.cream-bottle-1 {
  top: 33%;
  left: 8%;
  transform: translateY(calc(-1 * var(--scrollY) * 0.3)); /* Parallax effect */
  width: 25%;
}

.cream-bottle-2 {
  top: 43%;
  right: 8%;
  transform: translateY(calc(-1 * var(--scrollY) * 0.5)); /* Parallax effect */
  width: 25%;
}

.cream-bottle-3 {
  bottom: 15%;
  left: 8%;
  transform: translateY(calc(-1 * var(--scrollY) * 0.7)); /* Parallax effect */
  width: 25%;
}

.cream-bottle-4 {
  bottom: 5%;
  right: 8%;
  transform: translateY(calc(-1 * var(--scrollY) * 0.9)); /* Parallax effect */
  width: 25%;
}
/* Scroll container when scrolled */
.scroll-container.scrolled {
  background-position: 0 20px; /* Adjust scroll amount */
}

.explore-buttonw {
  padding: 0.5rem 1rem;
  border: 2px solid #142d45;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 100% !important;
  font-size: 20px !important;
  font-family: arial;
  font-weight: normal;
}

.explore-buttonw:hover {
  background-color: #142d45;
  color: white;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 23%;
  position: absolute;
  bottom: -50px;
  opacity: 0;
  transition: opacity 1s ease, bottom 2s ease;
}

.button-container.visible {
  opacity: 1;
  bottom: 80px;
}

/* Mobil cihazlar için */
@media (max-width: 767px) {
  .scroll-container {
    height: auto;
    max-height: 180vh;
    border-radius: 0;
  }

  .title {
    font-size: 1.5rem;
  }

  .cream-bottle {
    width: 100px;
  }

  .cream-bottle-1 {
    top: 56%;
    left: -2%;
    width: 30%;
  }

  .cream-bottle-2 {
    top: 64%;
    right: 5%;
    width: 30%;
    right: -2%;
  }

  .cream-bottle-3 {
    bottom: 16%;
    left: 5%;
    width: 30%;
    left: -2%;
  }

  .cream-bottle-4 {
    bottom: 9%;
    right: 5%;
    width: 30%;
    right: -2%;
  }
  .button-container {
    width: 70%;
    display: none;
    justify-content: center;
    bottom: 20px;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .button-container.visible {
    display: flex;
    opacity: 1;
  }

  .explore-buttonw {
    margin-top: 10px;
    width: auto;
    /* font-size: 15px !important; */
    /* background-color: #142d45; */
    color: #142d45;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  .weus {
    font-size: 19px !important;
  }

  .arrow {
    font-size: 1.5rem;
  }

  .text-container {
    margin-top: -128%;
    margin-right: -12px;
  }
  .bigT {
    font-size: 43px !important;
  }
  .nrml {
    font-size: 35px !important;
  }

  .logoo {
    width: 62%;
    margin: 0 auto !important;
  }
}
