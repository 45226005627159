.genessis-navbar {
  position: relative;
  height: 80px;

  .genessis-navbar-content {
    width: 100%;
    height: 80px;
    background-color: var(--bodyGrey);

    @media screen and (max-width: 768px) {
      height: 91px;
    }
  }

  .wrapper {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 80px;

    #main-form {
      .form-navbar {
        background-color: transparent;
      }

      .btn-navbar {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        margin: 0;
        width: 40px;
        display: flex;
        align-items: center;

        &:hover {
          color: var(--mediumGreen);
        }
      }
    }

    .nav-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 30px;

      @media screen and (max-width: 768px) {
        display: none;
      }

      .genessis-ul-navbar {
        display: flex;
        align-items: center;
        justify-content: center;

        .dropdown {
          position: relative;

          .dropbtn {
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            color: var(--textColor);
            font-size: 15px;
            font-family: "Arial MT", sans-serif;
            font-weight: bold;
            transition: 0.2s ease-in-out;
            letter-spacing: normal;
            background-color: transparent;
            padding: 12px 4px
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: var(--bodyGrey);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            z-index: 1;
            min-width: 183px;

            a {
              color: var(--textColor) !important;
              padding: 12px 4px !important;
              text-decoration: none;
              display: block;
              font-size: 15px !important;
              letter-spacing: normal !important;
              font-weight: bold;

              &:hover {
                background-color: var(--textColor) !important;
                color: #ffffff !important;
              }
            }

            .dropdown-submenu {
              position: relative;

              &:hover .dropdown-submenu-content {
                display: block;
              }

              .dropdown-submenu-content {
                display: none;
                position: absolute;
                left: 100%;
                top: 0;
                background-color: var(--bodyGrey);
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                a {
                  color: var(--textColor) !important;
                  padding: 12px 4px !important;
                  text-decoration: none;
                  display: block;
                  font-size: 15px !important;
                  letter-spacing: normal !important;
                  font-weight: bold;

                  &:hover {
                    background-color: var(--textColor) !important;
                    color: #ffffff !important;
                  }
                }
              }
            }
          }

          &:hover .dropdown-content {
            display: block;
            z-index: 9999;
          }
        }

        .list {
          list-style: none;
          position: relative;
          margin: 0 18px;

          a {
            text-decoration: none;
            color: var(--textColor);
            font-size: 16px;
            font-family: "Arial MT", sans-serif;
            font-weight: bold;
            transition: 0.2s ease-in-out;
            letter-spacing: 0.5px;
            background-color: transparent;
          }
        }
      }
    }
  }

  .logo {
    width: 228px;
    @media screen and (max-width: 768px) {
      width: 280px;
      margin-top: 10px;
      margin-left: 102px;
    }
  }

  @media screen and (max-width: 768px) {
    .genessis-ul-navbar {
      display: none;
    }

    .wrapper .logo-container {
      position: absolute;
      right: 29px;
    }
  }
}
