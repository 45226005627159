@media screen and (max-width: 768px) {
  .searchForm {
    display: none;
  }
  .searchFormMobil {
    display: block;
    position: relative;
    z-index: 10;
  }
  .mobil-search-input {
    color: var(--textColor);
  }
  .search-dropdown {
    z-index: 1000;
    background-color: #f27d41;
    position: absolute;
    width: 82%;
    border: 1px solid gray;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 15rem;
    overflow-y: auto;
  }
}
/* 
.searchFormMobil{
  display: none;
} */
/* .dropdown {
  z-index: 90;
} */

.absolute {
  z-index: 90;
}
