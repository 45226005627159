/* Masaüstü görünümü */
.footer-content {
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-bottom: 2px solid #808080;
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 30px;
}
.footer-container {
  background-color: var(--bodyGrey);
}
.footer-btn span {
  padding: 0.55rem 1rem !important;
  border: 2px solid #142d45 !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  font-weight: 600 !important;
  /* transition: background-color 0.3s, color 0.3s, border-color 0.3s !important; */
  width: auto !important;
  /* outline: 1px solid #ccc !important; */
}

.footer-btn span:hover {
  background-color: #142d45 !important;
  color: white !important;
  border-color: #0f1e30 !important;
}
.mob-logo {
  display: none;
}
.nav-items a{
  font-weight: 600;
}
.heroku {
  border: 1px solid #0f1e30;
  height: 40px !important;
  margin-top: -25px !important;
}
.butone {
  margin-top: 4px;
}
.ques {
  margin-left: 5px;
}


/* Button styles */
.footer-btn button {
  padding: 0.5rem 1rem;
  border: 1px solid #142d45;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  /* transition: background-color 0.3s, color 0.3s, border-color 0.3s;  */
  width: auto;
  outline: 2px solid #ccc;
}
.butone {
  margin-top: 4px;
}

.footer-btn button:hover {
  background-color: #142d45;
  color: white;
  border-color: #0f1e30;
}
.heroku {
  width: 75%;
  margin-top: 5px !important;
  margin: 0 auto;
  text-align: center;
  margin-left: 4px;
}


@media screen and (max-width: 767px) {
  /* Mobil görünüm */
  .footer-content {
    font-size: 16px;
    display: block !important;
  }
  .footer-mobile-logo {
    margin-left: 4.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mob-logo {
    display: block;
  }
  .footer-laptop {
    display: none !important;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .footer-content > div {
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
  }
  .footer-btn {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-btn span {
    display: block;
    width: 100%;
    text-align: center;
  }
  .heroku {
    margin-left: 0px;
    width: 100%;
    font-size: 14px;
  }
}
