.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  /* background-color: #fafafa; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservation-form {
  width: 100%;
  max-width: 917px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.3);
  z-index: 2;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.reser-form {
  background-color: #479ea8;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #1a4b72);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.reser-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #479ea8;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.form-container {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.reser-title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

.service-select {
  background-color: #479ea8;
}
.select-scroll {
  max-height: 100px;
  /* overflow-y: auto; */
  background-color: #479ea8;
}
.react-datepicker-popper {
  z-index: 3 !important;
}
.date-btn-previous {
  position: absolute;
  top: 2px;
  left: 0;
}
.date-btn-next {
  position: absolute;
  top: 2px;
  right: 3%;
}
.react-datepicker {
  font-family: inherit !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #1a4b72 !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 2;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #1a4b72;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 1;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #479ea8;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.reser-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.reser-info .reser-title {
  color: #1a4b72;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.reser-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #1a4b72;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #479ea8, #1a4b72);
  bottom: 49%;
  right: 44%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}
@media (max-width: 850px) {
  .reservation-form {
    grid-template-columns: 1fr;
    margin-top: 38px;
  }

  .reser-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .reser-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .reser-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .reser-info {
    padding: 1.7rem 1.6rem;
  }

  .reser-title {
    font-size: 1.3rem;
    text-align: center;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}
