#bgd {
  margin-top: 30px;
}

#bgd .blog-detail-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#bgd .back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: #032742;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

#bgd .back-button:hover {
  background-color: #f0f0f0;
}

#bgd .blog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#bgd .blog-title {
  font-size: 2.5em;
}

#bgd .blog-time {
  font-size: 14px;
  color: #999;
}

#bgd .blog-category {
  font-size: 1.2em;
  font-weight: bold;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
  margin-top: -17px;
}

#bgd .blog-image {
  width: 40%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-left: 30px;
}

#bgd .blog-description {
  font-size: 1.1em;
  line-height: 1.6;
  margin-right: 120px;
  margin-top: 10px;
}

#bgd .blog-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

#bgd .author-left {
  display: flex;
  align-items: center;
}

#bgd .author-right {
  font-size: 14px;
  color: #999;
}

#bgd .icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

#bgd .read-time,
#bgd .watch-time {
  font-size: 14px;
  color: #666;
}

#bgd .blog-content {
  display: flex;
  align-items: flex-start;
}

#bgd .blog-image {
  width: 40%;
  height: auto;
  margin-right: 20px;
  border-radius: 10px;
}

#bgd .blog-description {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  flex: 1;
}

.video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center !important;
  margin-left: 200px;
  margin-top: -10px;
}


@media (max-width: 768px) {
  #bgd .blog-detail-container {
    padding: 10px;
  }

  #bgd .blog-title {
    font-size: 1.8em;
  }

  #bgd .blog-category {
    font-size: 1em;
    margin-top: 0;
  }

  #bgd .blog-image {
    width: 100%;
    margin-left: 0;
  }

  #bgd .blog-description {
    font-size: 1em;
    margin-right: 20px;
    margin-left: 20px;
    text-align: center !important;
  }

  #bgd .blog-content {
    flex-direction: column;
  }

  #bgd .blog-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .video-wrapper {
    margin-left: 0;
    width: 370px;
    height: auto;
    margin-left: 12px;
  }

  #bgd .blog-video {
    width: 100%;
    height: auto;
  }
}