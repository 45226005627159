@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@400;600&display=swap");

.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;  */
  gap: 2rem; 
  font-family: "Poppins", sans-serif;
  margin-top: 50px;
  color: var(--textColor) !important;
}

.blog-header-section {
  width: 100%;
  padding-right: 0;
  margin-bottom: 2rem;
}

.blog-cards-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.blog-header {
  text-align: left !important;
}

.blog-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.blog-description {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align: left !important;
}

.explore-btn {
  padding: 0.5rem 1rem;
  border: 2px solid #142d45;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.explore-btn:hover {
  background-color: #142d45;
  color: white;
}

.blog-card {
  flex: 1 1 calc(33.333% - 2rem); /* Kartları yan yana üç tane olacak şekilde ayarladık */
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 2rem;
}


.blog-card:hover {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}

.blog-card-image {
  flex: 0 0 auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-position: center;
}

.blog-card-content {
  padding: 1rem;
}

.blog-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

.blog-card-description {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.5em;
}

.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.blog-card-read-time {
  font-size: 0.8rem;
  color: #666;
  flex: 1;
}

@media (min-width: 768px) {
  .blog-container {
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: flex-start;
  }

  .blog-header-section {
    flex: 0 0 30%;
    padding-right: 2rem;
    margin-bottom: 0;
  }

  .blog-cards-section {
    flex: 0 0 65%;
  }

  .blog-card {
    flex-direction: row;
  }

  .blog-card-image {
    flex: 0 0 200px;
  }

  .blog-card-title {
    display: none !important;
    color: transparent !important;
  }

  .blog-card-content h3 {
    display: none !important;
  }

  .iccon {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
  }
}

@media (max-width: 767px) {
  .blog-container {
    grid-template-columns: 1fr; /* Mobilde kartlar tek sütun */
  }
  .blog-card {
    flex: 1 1 100%; /* Mobilde kartlar tek sütun */
  }
  .blog-card-title {
    display: none;
  }

  .blog-title {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
  }

  .blog-description {
    text-align: center;
  }

  .explore-btn {
    font-size: 0.8rem;
  }

  .blog-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .iccon {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
  }
}

@media (max-width: 991px) {
  .blog-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .blog-card {
    flex: 1 1 calc(50% - 2rem); /* Tablet boyutunda iki kart yan yana */
  }
  .blog-card-title {
    display: none;
  }

  .blog-title {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
  }

  .blog-description {
    text-align: center;
  }

  .explore-btn {
    font-size: 0.8rem;
  }

  .blog-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .iccon {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
  }
}
