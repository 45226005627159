.collections-container {
  width: 90% !important;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.collections-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.collections-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin: 1px;
}

.collection-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 390px;
}

.collection-item img {
  width: 100%;
  height: 100%;
  display: block;
  /* transition: transform 0.3s ease; */
  object-fit: cover;
}

/* .collection-item:hover img {
  transform: scale(1.1);
} */

.collection-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collection-name {
  font-size: 1.8rem;
  margin: 0;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.collection-btn {
  display: flex;
  align-items: start;
  justify-content: flex-end;
  background-color: black;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}

/* .collection-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
} */

.btn-arrow {
  font-size: 1.4rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
  margin-right: 3px;
  text-align: center;
  /* font-weight: bold; */
  color: white;
  transform: rotate(-45deg); /* Kuzeydoğu yönüne bakacak şekilde döndür */
}

.maincontainer {
  position: absolute;
  width: 250px;
  height: 320px;
  background: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* THE CARD HOLDS THE FRONT AND BACK FACES */
.thecard {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

/* THE PSUEDO CLASS CONTROLS THE FLIP ON MOUSEOVER AND MOUSEOUT */
.thecard:hover {
  transform: rotateY(180deg);
}

/* THE FRONT FACE OF THE CARD, WHICH SHOWS BY DEFAULT */
.thefront {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
}

/* THE BACK FACE OF THE CARD, WHICH SHOWS ON MOUSEOVER */
.theback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
  background: #fafafa;
  color: #333;
  text-align: center;
  transform: rotateY(180deg);
}

/*This block (starts here) is merely styling for the flip card, and is NOT an essential part of the flip code */
.thefront h5,
.theback h5 {
  /* font-family: 'zilla slab', sans-serif; */
  padding: 30px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: var(--textColor);
}

.thefront p,
.theback p {
  /* font-family: 'zilla slab', sans-serif; */
  padding: 30px;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: var(--textColor);
}

/* Medya sorguları */
@media (max-width: 768px) {
  .collections-grid {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thecard {
    height: 130px;
  }
  .thefront h5,
  .theback h5 {
    display: none;
  }
  .thefront p,
  .theback p {
    padding: 9px;
  }
  .thecard:hover {
    transform: none;
  }
}
